option:disabled{
  opacity: 1;
  background: #b5bbc8 !important;
  color : black;
  font-style: italic;
  font-size: 10px;
  }​
  .show-content{
    display: none;
  }
  .twitter-typeahead .tt-hint
  {
    display: none;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    border: 1px solid transparent;
    border-radius:4px;
  }

  .twitter-typeahead .hint-small
  {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
    line-height: 1.5;
  }

  .twitter-typeahead .hint-large
  {
    height: 45px;
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
    line-height: 1.33;
  }
  .tt-dropdown-menu {
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    margin-top: 12px;
    padding: 8px 0;
    width: 100%;
    height: 124px;
    overflow-y: auto;
  }
  .tt-suggestion {
    font-size: 0.9em;
    line-height: 24px;
    padding: 3px 20px;
  }
  .tt-suggestion.tt-is-under-cursor {
    background-color: #0097CF;
    color: #FFFFFF;
  }
  .tt-suggestion p {
    margin: 0;
  }
  .btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }
  .btn-circle.btn-pru {
    width: 30px;
    height: 30px;
    padding: 3px 3px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 25px;
  }
  .btn-circle.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: 24px;
    line-height: 1.33;
    border-radius: 35px;
  }

  .btn-group-radio input[type=radio] {
    visibility: hidden;
    position: absolute !important;
    top: -9999px !important;
    left: -9999px !important;
  }

  .btn-group-radio input[type=radio]:checked + .btn {
    color: #333333;
    background-color: #e6e6e6;
    *background-color: #d9d9d9;
    background-color: #cccccc \9;
    background-color: #e6e6e6;
    background-color: #d9d9d9 \9;
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  .btn-group-radio > input:first-child + .btn {
    margin-left: 0;
    -webkit-border-top-left-radius: 4px;
    -moz-border-radius-topleft: 4px;
    border-top-left-radius: 4px;
    -webkit-border-bottom-left-radius: 4px;
    -moz-border-radius-bottomleft: 4px;
    border-bottom-left-radius: 4px;
  }
  .btn-group-radio > input:first-child + .btn.large {
    margin-left: 0;
    -webkit-border-top-left-radius: 6px;
    -moz-border-radius-topleft: 6px;
    border-top-left-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    border-bottom-left-radius: 6px;
  }

  .btn-group-radio > .btn {
    margin-left: -1px;
  }
  /*----------------------------------
  Yes/No items
  ----------------------------------*/
  #Notas-Penetracion label {
    display: block;
    position: relative;
    padding: 0.5rem 1rem;
    line-height: 28px;
    font-weight: normal;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  #Notas-Penetracion label:last-of-type { margin-right: 1rem; }

  #Notas-Penetracion label i {
    display: inline-block;
    height: 25px;
    position: relative;
    top: 6px;
    font-style: normal;
    color: #ccc;
  }

  #Notas-Penetracion label span {
    display: inline-block;
    margin-left: 5px;
    line-height: 25px;
    color: gray;
  }

  #Notas-Penetracion input[type="radio"],
  #Notas-Penetracion input[type="checkbox"] { display: none; }

  #Notas-Penetracion input[type="radio"] + i:before,
  #Notas-Penetracion input[type="checkbox"] + i:before {
    font-family: 'FontAwesome';
    font-size: 28px;
    height: 25px;
    width: 25px;
    display: inline-block;
  }

  #Notas-Penetracion input[type="radio"]:checked + i,
  #Notas-Penetracion input[type="checkbox"]:checked + i {
    position: relative;
    -webkit-animation: icon-beat 0.1s ease;
    animation: icon-beat 0.1s ease;
  }

  #Notas-Penetracion input[type="radio"]:checked + i + span,
  #Notas-Penetracion input[type="checkbox"]:checked + i + span {
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
  }

  #Notas-Penetracion input[type="radio"] + i:before { content: "\f10c"; }

  #Notas-Penetracion input[type="radio"]:checked + i:before { content: "\f00c"; }

  #Notas-Penetracion input[type="radio"]:checked + i + span,
  #Notas-Penetracion input[type="radio"]:checked + i:before { color: rgba(0, 128, 128, 0.5); }

  #Notas-Penetracion input[type="checkbox"] + i:before { content: "\f096"; }

  #Notas-Penetracion input[type="checkbox"]:checked + i:before { content: "\f046"; }

  #Notas-Penetracion input[type="checkbox"]:checked + i + span,
  #Notas-Penetracion input[type="checkbox"]:checked + i:before { color: rgba(0, 128, 0, 0.5); }

  /*----------------------------------
  Custom Radio Button
  ----------------------------------*/
  #prum-radiobtn label {
    display: block;
    position: relative;
    padding: 0.5rem 1rem;
    line-height: 28px;
    font-weight: normal;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  #prum-radiobtn label:last-of-type { margin-right: 1rem; }

  #prum-radiobtn label i {
    display: inline-block;
    height: 25px;
    position: relative;
    top: 6px;
    font-style: normal;
    color: #ccc;
  }

  #prum-radiobtn label span {
    display: inline-block;
    margin-left: 5px;
    line-height: 25px;
    color: gray;
  }

  #prum-radiobtn input[type="radio"],
  #prum-radiobtn input[type="checkbox"] { display: none; }

  #prum-radiobtn input[type="radio"] + i:before,
  #prum-radiobtn input[type="checkbox"] + i:before {
    font-family: 'FontAwesome';
    font-size: 28px;
    height: 25px;
    width: 25px;
    display: inline-block;
  }

  #prum-radiobtn input[type="radio"]:checked + i,
  #prum-radiobtn input[type="checkbox"]:checked + i {
    position: relative;
    -webkit-animation: icon-beat 0.1s ease;
    animation: icon-beat 0.1s ease;
  }

  #prum-radiobtn input[type="radio"]:checked + i + span,
  #prum-radiobtn input[type="checkbox"]:checked + i + span {
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
  }

  #prum-radiobtn input[type="radio"] + i:before { content: "\f10c"; }

  #prum-radiobtn input[type="radio"]:checked + i:before { content: "\f00c"; }

  #prum-radiobtn input[type="radio"]:checked + i + span,
  #prum-radiobtn input[type="radio"]:checked + i:before { color: rgba(0, 128, 128, 0.5); }

  #prum-radiobtn input[type="checkbox"] + i:before { content: "\f096"; }

  #prum-radiobtn input[type="checkbox"]:checked + i:before { content: "\f046"; }

  #prum-radiobtn input[type="checkbox"]:checked + i + span,
  #prum-radiobtn input[type="checkbox"]:checked + i:before { color: rgba(0, 128, 0, 0.5); }

  /*Admin log*/
  pre {
    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }
  /*hide element datatable*/
  #dataTable span,#dataTableinternal span,#dataTableexternal span {
    display:none;
  }
  tr.group,
  tr.group:hover {
    background-color: #ddd !important;
  }
  /* no scrollbar */
  .bloc { display:inline-block; vertical-align:top; overflow:hidden;}
  .bloc select { padding:10px; margin:-5px -20px -5px -5px; }

  /* Legend - Fieldset Border */
  fieldset.scheduler-border {
    border: 1px groove #ddd !important;
    padding: 0 1.4em 1.4em 1.4em !important;
    margin: 0 0 1.5em 0 !important;
    -webkit-box-shadow:  0px 0px 0px 0px #000;
    box-shadow:  0px 0px 0px 0px #000;
  }

  legend.scheduler-border {
    font-size: 1.2em !important;
    font-weight: bold !important;
    text-align: left !important;
    width:auto;
    padding:0 10px;
    border-bottom:none;
  }
.back-to-top {
  opacity: 0.8;
  filter: alpha(opacity=80);
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    left: 20px;
    display:none;
    z-index: 1000;
}
